import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/src/components/layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "terms-and-conditions",
      "style": {
        "position": "relative"
      }
    }}>{`TERMS AND CONDITIONS`}</h1>
    <SEO title={props.pageContext.frontmatter.title} mdxType="SEO"></SEO>
    <p>{`Last Revised on July 24, 2020.`}</p>
    <p>{`These Terms and Conditions (these "`}<strong parentName="p">{`Terms`}</strong>{`") apply to use access and use of the website, `}<a parentName="p" {...{
        "href": "http://www.chromehearts.com"
      }}>{`www.chromehearts.com`}</a>{` (the "`}<strong parentName="p">{`Website`}</strong>{`"),  operated on behalf of Chrome Hearts LLC ("`}<strong parentName="p">{`Company`}</strong>{`", "`}<strong parentName="p">{`we`}</strong>{`" or "`}<strong parentName="p">{`us`}</strong>{`"), and together with any online content, tools, features and functionality offered on or through our Website  (the "`}<strong parentName="p">{`Services`}</strong>{`"), as well as any pay by link services operated on behalf of Chrome Hearts LLC, Chrome Hearts UK Ltd, Chrome Hearts Paris LLC, Chrome Hearts Saint Barth SAS, Chrome Hearts Taipei, Chrome Hearts Hong Kong Ltd, or Chrome Hearts Beijing Trading Co.`}</p>
    <p>{`These Terms govern your access to and use of the Services, including any purchases you make through the Services. Please read these Terms carefully, as they include important information about your legal rights. By accessing and/or using the Services, you are agreeing to these Terms. If you do not understand or agree to these Terms, please do not use the Services.`}</p>
    <p>{`For purposes of these Terms, "`}<strong parentName="p">{`you`}</strong>{`" and "`}<strong parentName="p">{`your`}</strong>{`" means you as the user of the Services. If you use the Services on behalf of a company or other entity then "you" includes you and that entity, and you represent and warrant that (a) you are an authorized representative of the entity with the authority to bind the entity to these Terms, and (b) you agree to these Terms on the entity's behalf.`}</p>
    <p><strong parentName="p">{`Please note that Section 9 contains an arbitration clause and class action waiver. By agreeing to these Terms, you agree (a) to resolve all disputes with us through binding individual arbitration, which means that you waive any right to have those disputes decided by a judge or jury, and (b) that you waive your right to participate in class actions, class arbitrations, or representative actions. You have the right to opt-out of arbitration as explained in Section 9.`}</strong></p>
    <h2 {...{
      "id": "table-of-contents",
      "style": {
        "position": "relative"
      }
    }}>{`TABLE OF CONTENTS`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-who-may-use-the-services"
        }}>{`1. WHO MAY USE THE SERVICES`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-user-accounts-subscriptions-and-free-trials"
        }}>{`2. USER ACCOUNTS, SUBSCRIPTIONS AND FREE TRIALS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-orders-for-products-andor-services"
        }}>{`3. ORDERS FOR PRODUCTS AND/OR SERVICES`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-location-of-our-privacy-policy-and-other-applicable-policies"
        }}>{`4. LOCATION OF OUR PRIVACY POLICY AND OTHER APPLICABLE POLICIES`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#5-rights-we-grant-you"
        }}>{`5. RIGHTS WE GRANT YOU`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#6-ownership-and-content"
        }}>{`6. OWNERSHIP AND CONTENT`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#7-third-party-services-and-materials"
        }}>{`7. THIRD PARTY SERVICES AND MATERIALS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#8-disclaimers-limitations-of-liability-and-indemnification"
        }}>{`8. DISCLAIMERS, LIMITATIONS OF LIABILITY AND INDEMNIFICATION`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#9-arbitration-and-class-action-waiver"
        }}>{`9. ARBITRATION AND CLASS ACTION WAIVER`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#10-additional-provisions"
        }}>{`10. ADDITIONAL PROVISIONS`}</a></li>
    </ul>
    <h2 {...{
      "id": "1-who-may-use-the-services",
      "style": {
        "position": "relative"
      }
    }}>{`1. WHO MAY USE THE SERVICES`}</h2>
    <p>{`You must be 13 years of age or older and reside in the United States or any of its territories to use the Services. Minors under the age of majority in their jurisdiction but that are at least 13 years of age are only permitted to use the Services if the minor’s parent or guardian accepts these Terms on the minor’s behalf prior to use of the Services. Children under the age of 13 are not permitted to use the Services. By using the Services, you represent and warrant that you meet these requirements.`}</p>
    <h2 {...{
      "id": "2-user-accounts-subscriptions-and-free-trials",
      "style": {
        "position": "relative"
      }
    }}>{`2. USER ACCOUNTS, SUBSCRIPTIONS AND FREE TRIALS`}</h2>
    <p><em parentName="p">{`Creating and Safeguarding your Account`}</em>{`. To use certain of the Services, you have the option to create an account ("`}<strong parentName="p">{`Account`}</strong>{`"). You agree to provide us with accurate, complete and updated information for your Account. You can access, edit and update your Account via account settings portion of the Website. You are solely responsible for any activity on your Account and for maintaining the confidentiality and security of your password. We are not liable for any acts or omissions by you in connection with your Account. You must immediately notify us at `}<a parentName="p" {...{
        "href": "mailto:privacy@chromehearts.com"
      }}>{`privacy@chromehearts.com`}</a>{` if you know or have any reason to suspect that your Account or password have been stolen, misappropriated or otherwise compromised, or in case of any actual or suspected unauthorized use of your Account.`}</p>
    <h2 {...{
      "id": "3-orders-for-products-andor-services",
      "style": {
        "position": "relative"
      }
    }}>{`3. ORDERS FOR PRODUCTS AND/OR SERVICES`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Payment`}</em>{`. The Services may permit you to purchase certain physical products through the Services, ("`}<strong parentName="p">{`Offerings`}</strong>{`"). You acknowledge and agree that all information you provide with regards to a purchase of Offerings, including, without limitation, credit card or other payment information, is accurate, current and complete. You represent and warrant that you have the legal right to use the payment method you provide to us or our payment processor, including, without limitation, any credit card you provide when completing a transaction. We reserve the right, with or without prior notice and in our sole and complete discretion, to (a) discontinue, modify, or limit the available quantity of, any Offerings, and (b) refuse to allow any user to purchase any Offering or deliver such Offerings to a user or a user designated address. When you purchase Offerings, you (a) agree to pay the price for such Offerings as set forth in the applicable Service, and all shipping and handling charges and all applicable taxes in connection with your purchase (the "`}<strong parentName="p">{`Full Purchase Amount`}</strong>{`"), and (b) authorize us to charge your credit card or other payment method for the Full Purchase Amount.  Unless otherwise noted, all currency references are in U.S. Dollars. All fees and charges are payable in accordance with payment terms in effect at the time the fee or the charge becomes payable. Payment can be made by credit card, debit card or other means that we may make available. Orders will not be processed until payment has been received in full, and any holds on your account by any payment processor are solely your responsibility.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Promotional Codes`}</em>{`. We may offer certain promotional codes, referral codes, discount codes, coupon codes or similar offers ("`}<strong parentName="p">{`Promotional Codes`}</strong>{`") that may be redeemed for discounts on future Offerings, or other features or benefits related to the Services, subject to any additional terms that the Company establishes. You agree that Promotional Codes: (a) must be used in a lawful manner; (b) must be used for the intended audience and purpose; (c) may not be duplicated, sold or transferred in any manner, or made available by you to the general public (whether posted to a public forum, coupon collecting service, or otherwise), unless expressly permitted by the Company; (d) may be disabled or have additional conditions applied to them by the Company at any time for any reason without liability to the Company; (e) may only be used pursuant to the specific terms that the Company establishes for such Promotional Code; (f) are not valid for cash or other credits or points; and (g) may expire prior to your use.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Product Information`}</em>{`. We try to be as accurate as we can in our descriptions and display of the Offerings. However, we do not warrant or guarantee that Offering descriptions are accurate, complete, reliable, current, or error-free. The particular settings and technical specifications of your viewing device could affect the accuracy of its display of the Offerings and their colors. Any information regarding sizing and measurements are not exact and are provided for convenience only. If an Offering is not as described on the Services, your sole remedy is to return it in an unused condition.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Product Availability`}</em>{`. The inclusion of any Offerings for purchase through the Services at a particular time does not imply or warrant that the Offerings will be available at any other time. Offerings that are displayed as available may become unavailable due to inventory changes or delays in updating the Services.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Changes and Pricing`}</em>{`. The Company may, at any time, revise or change the pricing, availability, specifications, content, descriptions or features of any Offerings.  We reserve the right to change prices for Offerings displayed on the Services at any time, and to correct pricing errors that may inadvertently occur (and to cancel any orders in our sole discretion that were purchased with pricing errors). All such changes shall be effective immediately upon posting of such new Offering prices to the Services and/or upon making the customer aware of the pricing error.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Order Acceptance; Shipment`}</em>{`. Once we receive your order for an Offering, we will provide you with an order confirmation. Your receipt of an order confirmation, however, does not signify our acceptance of your order, nor does it constitute confirmation of our offer to sell; we are simply confirming that we received your order. We reserve the right at any time after receiving your order to accept or decline your order for any reason and in our sole discretion. If we cancel an order after you have already been billed, then we will refund the billed amount. Title and risk of loss for any purchases of physical products pass to you upon our delivery to our carrier. We reserve the right to ship partial orders (at no additional cost to you), and the portion of any order that is partially shipped may be charged at the time of shipment. All orders are shipped using one of our third party couriers. Online tracking may be available at our courier’s website (for example, FedEx), though we make no warranties regarding its availability because it is not under our control. While deliveries may be scheduled for a specified arrival, we cannot guarantee delivery by any specific date or time.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Return, Refund and Exchange Policy`}</em>{`. Our Return, Refund and Exchange Policy for returns, refunds or exchanges of Offerings on the Services can be accessed at `}<a parentName="p" {...{
            "href": "https://terms.chromehearts.com"
          }}>{`terms.chromehearts.com`}</a>{`, the terms and conditions of which are incorporated by reference in these Terms as if fully set forth in these Terms.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`No Delivery to Children`}</em>{`. In furtherance of our policy of not collecting personal information from persons under the age of 13, users are not allowed to give the Company the personal information of any persons under the age of 13 for delivery or shipping purposes or any other reason.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "4-location-of-our-privacy-policy-and-other-applicable-policies",
      "style": {
        "position": "relative"
      }
    }}>{`4. LOCATION OF OUR PRIVACY POLICY AND OTHER APPLICABLE POLICIES`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Privacy Policy`}</em>{`. Our Privacy Policy describes how we handle the information you provide to us when you use the Services. For an explanation of our privacy practices, please visit our Privacy Policy located at `}<a parentName="p" {...{
            "href": "/privacy-policy"
          }}>{`terms.chromehearts.com/privacy-policy`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`California Transparency in Supply Chains Act Disclosure`}</em>{`.  Our California Transparency in Supply Chains Act Disclosure can be accessed at `}<a parentName="p" {...{
            "href": "https://terms.chromehearts.com"
          }}>{`terms.chromehearts.com`}</a>{`.  `}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "5-rights-we-grant-you",
      "style": {
        "position": "relative"
      }
    }}>{`5. RIGHTS WE GRANT YOU`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`License Grant`}</em>{`. Subject to your compliance with these Terms, the Company hereby grants to you, a personal, worldwide, royalty-free, non-assignable, non-sublicensable, non-transferrable, and non-exclusive license to use the software provided to you as part of the Services . This license has the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by us, in the manner permitted by these Terms and subject to the use restrictions described below. Your access and use of the Services may be interrupted from time to time for any of several reasons, including, without limitation, the malfunction of equipment, periodic updating, maintenance or repair of the Service or other actions that Company, in its sole discretion, may elect to take.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Restrictions On Your Use of the Services`}</em>{`. You may not do any of the following, unless applicable laws or regulations prohibit these restrictions or you have our written permission to do so:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`download, modify, copy, distribute, transmit, display, perform, reproduce, duplicate, publish, license, create derivative works from, or offer for sale any information contained on, or obtained from or through, the Services;`}</li>
          <li parentName="ol">{`duplicate, decompile, reverse engineer, disassemble or decode the Services (including any underlying idea or algorithm), or attempt to do any of the same;`}</li>
          <li parentName="ol">{`use, reproduce or remove any copyright, trademark, service mark, trade name, slogan, logo, image, or other proprietary notation displayed on or through the Services;`}</li>
          <li parentName="ol">{`use automation software (bots), hacks, modifications (mods) or any other unauthorized third-party software designed to modify the Services;`}</li>
          <li parentName="ol">{`exploit the Services for any commercial purpose, including without limitation communicating or facilitating any commercial advertisement or solicitation;`}</li>
          <li parentName="ol">{`access or use the Services in any manner that could disable, overburden, damage, disrupt or impair the Services or interfere with any other party's access to or use of the Services or use any device, software or routine that causes the same;`}</li>
          <li parentName="ol">{`attempt to gain unauthorized access to, interfere with, damage or disrupt the Services, accounts registered to other users or the computer systems or networks connected to the Services;`}</li>
          <li parentName="ol">{`circumvent, remove, alter, deactivate, degrade or thwart any technological measure or content protections of the Services;`}</li>
          <li parentName="ol">{`use any robot, spider, crawlers or other automatic device, process, software or queries that intercepts, "mines," scrapes or otherwise accesses the Services to monitor, extract, copy or collect information or data from or through the Services, or engage in any manual process to do the same;`}</li>
          <li parentName="ol">{`introduce any viruses, trojan horses, worms, logic bombs or other materials that are malicious or technologically harmful into our systems;`}</li>
          <li parentName="ol">{`use the Services for illegal, harassing, unethical, or disruptive purposes;`}</li>
          <li parentName="ol">{`violate any applicable law or regulation in connection with your access to or use of the Services; or`}</li>
          <li parentName="ol">{`access or use the Services in any way not expressly permitted by these Terms.`}</li>
        </ol>
      </li>
    </ol>
    <h2 {...{
      "id": "6-ownership-and-content",
      "style": {
        "position": "relative"
      }
    }}>{`6. OWNERSHIP AND CONTENT`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Ownership of the Services`}</em>{`. The Services, including their "look and feel" (e.g., text, graphics, images, logos), proprietary content, information and other materials, are protected under copyright, trademark and other intellectual property laws. You agree that the Company and/or its licensors own all right, title and interest in and to the Services (including any and all intellectual property rights therein) and you agree not to take any action(s) inconsistent with such ownership interests.  We and our licensors reserve all rights in connection with the Services and its content, including, without limitation, the exclusive right to create derivative works.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Ownership of Trademarks`}</em>{`. The Company’s name and logo and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors.  Other names, logos, product and service names, designs and slogans that appear on the Services are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by us.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Ownership of Feedback`}</em>{`. We welcome feedback, comments and suggestions for improvements to the Services ("`}<strong parentName="p">{`Feedback`}</strong>{`"). You acknowledge and expressly agree that any contribution of Feedback does not and will not give or grant you any right, title or interest in the Services or in any such Feedback. All Feedback becomes the sole and exclusive property of the Company, and the Company may use and disclose Feedback in any manner and for any purpose whatsoever without further notice or compensation to you and without retention by you of any proprietary or other right or claim. You hereby assign to the Company any and all right, title and interest (including, but not limited to, any patent, copyright, trade secret, trademark, show-how, know-how, moral rights and any and all other intellectual property right) that you may have in and to any and all Feedback.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "7-third-party-services-and-materials",
      "style": {
        "position": "relative"
      }
    }}>{`7. THIRD PARTY SERVICES AND MATERIALS`}</h2>
    <ol>
      <li parentName="ol"><em parentName="li">{`Use of Third Party Materials in the Services`}</em>{`. Certain Services may display, include or make available content, data, information, applications or materials from third parties ("`}<strong parentName="li">{`Third Party Materials`}</strong>{`") or provide links to certain third party websites. By using the Services, you acknowledge and agree that the Company is not responsible for examining or evaluating the content, accuracy, completeness, availability, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third Party Materials or websites. We do not warrant or endorse and do not assume and will not have any liability or responsibility to you or any other person for any third-party services, Third Party Materials or third-party websites, or for any other materials, products, or services of third parties. Third Party Materials and links to other websites are provided solely as a convenience to you.  If you have any complaints in connection with any Third Party Materials or third-party website, please contact such third party directly, or contact your state Attorney General or the Federal Trade Commission at `}<a parentName="li" {...{
          "href": "http://www.ftc.gov"
        }}>{`www.ftc.gov`}</a>{`.`}</li>
    </ol>
    <h2 {...{
      "id": "8-disclaimers-limitations-of-liability-and-indemnification",
      "style": {
        "position": "relative"
      }
    }}>{`8. DISCLAIMERS, LIMITATIONS OF LIABILITY AND INDEMNIFICATION`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Disclaimers`}</em>{`. Your access to and use of the Services are at your own risk. You understand and agree that the Services are provided to you on an "AS IS" and "AS AVAILABLE" basis. WITHOUT LIMITING THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE COMPANY, ITS PARENTS, AFFILIATES, RELATED COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS AND LICENSORS (THE "`}<strong parentName="p">{`COMPANY ENTITIES`}</strong>{`") DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. THE COMPANY ENTITIES MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL RESPONSIBILITY AND LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, SECURITY OR RELIABILITY OF THE SERVICES; (B) ANY HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT RESULTS FROM YOUR ACCESS TO OR USE OF THE SERVICES; (C) THE OPERATION OR COMPATIBILITY WITH ANY OTHER APPLICATION OR ANY PARTICULAR SYSTEM OR DEVICE; AND (D) WHETHER THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS. No advice or information, whether oral or written, obtained from the Company Entities or through the Services, will create any warranty or representation not expressly made herein.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Limitations of Liability`}</em>{`. TO THE EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE COMPANY ENTITIES BE LIABLE (A) FOR DAMAGES OF ANY KIND, INCLUDING DIRECT, INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS, BUSINESS INTERRUPTION OR ANY OTHER  DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE ARISING IN ANY WAY IN CONNECTION WITH THE SERVICES OR THESE TERMS AND WHETHER IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) EVEN IF THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, OR (B) FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE DELIVERY, USE OR PERFORMANCE OF THE SERVICES.  SOME JURISDICTIONS (SUCH AS THE STATE OF NEW JERSEY) DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION MAY NOT APPLY TO YOU. THE COMPANY ENTITIES’ TOTAL LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED THE AMOUNT OF ONE HUNDRED DOLLARS ($100.00), OR THE AMOUNT YOU PAID THE COMPANY ENTITIES, IF ANY, IN THE PAST SIX (6) MONTHS FOR THE SERVICES (OR PRODUCTS PURCHASED ON THE SERVICES) GIVING RISE TO THE CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Indemnification`}</em>{`. By entering into these Terms and accessing or using the Services, you agree that you shall defend, indemnify and hold the Company Entities harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys’ fees and costs) incurred by the Company Entities arising out of or in connection with: (a) your violation or breach of any term of these Terms or any applicable law or regulation; (b) your violation of any rights of any third party; (c) your access to or use of the Services; or (d) your negligence or wilful misconduct.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "9-arbitration-and-class-action-waiver",
      "style": {
        "position": "relative"
      }
    }}>{`9. ARBITRATION AND CLASS ACTION WAIVER`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Informal Process First`}</em>{`.  You agree that in the event of any dispute between you and the Company Entities, you will first contact the Company and make a good faith sustained effort to resolve the dispute before resorting to more formal means of resolution, including without limitation, any court action.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Arbitration Agreement and Class Action Waiver`}</em>{`.  After the informal dispute resolution process, any remaining dispute, controversy, or claim (collectively, "`}<strong parentName="p">{`Claim`}</strong>{`") relating in any way to your use of the Company’s services and/or products, including the Services, will be resolved by arbitration, including threshold questions of arbitrability of the Claim. You and the Company agree that any Claim will be settled by final and binding arbitration, using the English language, administered by JAMS under its Comprehensive Arbitration Rules and Procedures (the "`}<strong parentName="p">{`JAMS Rules`}</strong>{`") then in effect (those rules are deemed to be incorporated by reference into this section, and as of the date of these Terms). `}<strong parentName="p">{`Arbitration will be handled by a sole arbitrator in accordance with the JAMS Rules. Judgment on the arbitration award may be entered in any court that has jurisdiction. Any arbitration under these Terms will take place on an individual basis – class arbitrations and class actions are not permitted. You understand that by agreeing to these Terms, you and the Company are each waiving the right to trial by jury or to participate in a class action or class arbitration.`}</strong>{` Notwithstanding the foregoing, you and the Company will have the right to bring an action in a court of proper jurisdiction for injunctive or other equitable or conservatory relief, pending a final decision by the arbitrator. You may instead assert your claim in "small claims" court, but only if your claim qualifies, your claim remains in such court and your claim remains on an individual, non-representative and non-class basis.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Costs of Arbitration`}</em>{`. Payment for any and all reasonable JAMS filing, administrative and arbitrator fees will be in accordance with the JAMS Rules. If the value of your claim does not exceed $10,000, the Company will pay for the reasonable filing, administrative and arbitrator fees associated with the arbitration, unless the arbitrator finds that either the substance of your claim or the relief sought was frivolous or brought for an improper purpose.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><em parentName="strong">{`Opt-Out`}</em>{`. You have the right to opt-out and not be bound by the arbitration provisions set forth in these Terms by sending written notice of your decision to the U.S. mailing address or email address listed in the "How to Contact Us" section of these Terms. The notice must be sent to the Company within thirty (30) days of your registering to use the Services or agreeing to these Terms, otherwise you shall be bound to arbitrate disputes in accordance with these Terms. If you opt-out of these arbitration provisions, the Company also will not be bound by them.`}</strong></p>
      </li>
    </ol>
    <h2 {...{
      "id": "10-additional-provisions",
      "style": {
        "position": "relative"
      }
    }}>{`10. ADDITIONAL PROVISIONS`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`SMS Messaging and Phone Calls`}</em>{`. Certain portions of the Services may allow us to contact you via telephone or text messages. You agree that the Company may contact you via telephone or text messages (including by an automatic telephone dialing system) at any of the phone numbers provided by you or on your behalf in connection with your use of the Services, including for marketing purposes. You understand that you are not required to provide this consent as a condition of purchasing any Products. You also understand that you may opt out of receiving text messages from us at any time, either by texting the word "STOP" back to the number that sent you the message, or by contacting us as set forth in the "How to Contact Us" section of these Terms below. If you do not choose to opt out, we may contact you as outlined in our Privacy Policy.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Updating These Terms`}</em>{`. We may modify these Terms from time to time in which case we will update the "Last Revised" date at the top of these Terms.  If we make changes that are material, we will use reasonable efforts to attempt to notify you, such as by e-mail and/or by placing a prominent notice on the first page of the Website. However, it is your sole responsibility to review these Terms from time to time to view any such changes.  The updated Terms will be effective as of the time of posting, or such later date as may be specified in the updated Terms. Your continued access or use of the Services after the modifications have become effective will be deemed your acceptance of the modified Terms.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Termination of License and Your Account`}</em>{`. If you breach any of the provisions of these Terms, all licenses granted by the Company will terminate automatically. Additionally, the Company may suspend, disable, or delete your Account and/or the Services (or any part of the foregoing) with or without notice, for any or no reason. If the Company deletes your Account for any suspected breach of these Terms by you, you are prohibited from re-registering for the Services under a different name. All sections which by their nature should survive the termination of these Terms shall continue in full force and effect subsequent to and notwithstanding any termination of this Agreement by the Company or you. Termination will not limit any of the Company’s other rights or remedies at law or in equity.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Injunctive Relief`}</em>{`. You agree that a breach of these Terms will cause irreparable injury to the Company for which monetary damages would not be an adequate remedy and the Company shall be entitled to equitable relief in addition to any remedies it may have hereunder or at law without a bond, other security or proof of damages.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`California Residents`}</em>{`. If you are a California resident, in accordance with Cal. Civ. Code § 1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Miscellaneous`}</em>{`. If any provision of these Terms shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions. These Terms and the licenses granted hereunder may be assigned by the Company but may not be assigned by you without the prior express written consent of the Company. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. The section headings used herein are for reference only and shall not be read to have any legal effect. The Services are operated by us in the United States. Those who choose to access the Services from locations outside the United States do so at their own initiative and are responsible for compliance with applicable local laws. These Terms are governed by the laws of the State of California, without regard to conflict of laws rules, and the proper venue for any disputes arising out of or relating to any of the same will be the arbitration venue set forth in Section 9, or if those do not apply, then the state and federal courts located in Los Angeles, California. You and the Company agree that the United Nations Convention on Contracts for the International Sale of Goods will not apply to the interpretation or construction of these Terms. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`How to Contact Us`}</em>{`.  You may contact us regarding the Services or these Terms at: 915 N. Mansfield Ave., Hollywood, CA 90038, by phone at +1 (855) 565-0542 or by e-mail at `}<a parentName="p" {...{
            "href": "mailto:privacy@chromehearts.com"
          }}>{`privacy@chromehearts.com`}</a>{`.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      